<script>
export default {
    props: {
      empresa: { type: Object, required: true },
      old_ordem_servico: { type: Object, required: true },
    },
  data() {
    return {
      titleBody: 'Alterar Histórico Padrão',
      historicoPadrao: {
        id: -1,
        descricao: null,
        status: true
      },
      serie: {
        nome: null,
        modelo: null,
        ult_nf: null,
        check_btn: null,
        incluso: null,
        alterado: null,
      }
    }
  },
  created() {},
  mounted() {
    this.$emit('newTitle', this.titleBody);
    this.historicoPadrao = this.old_ordem_servico;
  },
  methods: {
    setPut() {
      this.$emit('doPut', this.serie);
    },
  },
}
</script>

<template>
<div class="card">
    <div class="card-body">
      <form id="form-centro-resultado" role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-body>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_id" class="col-form-label">Código</label>
                  <div class="col">
                    <input disabled  class="form-control text-right" type="text"
                      placeholder="Gerado pelo sistema" id="centro_resultado_id">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Nome</label>
                  <div class="col">
                    <input v-model="serie.nome" class="form-control" type="text"
                      placeholder="Identificação do Histórico Padrão" id="centro_resultado_descricao">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Modelo</label>
                  <div class="col">
                    <input v-model="serie.modelo" class="form-control" type="text" placeholder="modelo"
                      id="centro_resultado_descricao">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Ultima NF</label>
                  <div class="col">
                    <input v-model="serie.ult_nf" class="form-control" type="text" placeholder="NF"
                      id="centro_resultado_descricao">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <div class="col">
                    <b-form-checkbox id="tipo-pagamento-touch" v-model="serie.check_btn" name="touch" value="1"
                      unchecked-value="0">
                      Ativo
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Incluso</label>
                  <div class="col">
                    <input v-model="serie.incluso" type="text" class="form-control" placeholder="digite" typeof="text">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Incluso</label>
                  <div class="col">
                    <input v-model="serie.alterado" type="text" class="form-control" placeholder="digite" typeof="text">
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </form>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click="setPut">Gravar</button>
      </div>
    </div>
  </div>
</template>