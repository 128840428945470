<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
    empresa: { type: Object, required: true },
  },

  components: {
    Multiselect,
  },
  data() {
    return {
      titleBody: 'Incluir Ordem Serviço',
      historicoPadrao: {
        id: -1,
        descricao: null,
        status: true
      },

      item_ordem:[
        "1-opção",
        "2-opção",
        "3-opção",
      ],

      ordem_servico: {
        nome: null,
        modelo: null,
        ult_nf: null,
        check_btn: null,
        incluso: null,
        alterado: null,
      }
    }
  },
  created() { },
  mounted() {
    this.$emit('newTitle', this.titleBody);
  },
  methods: {
    setPost() {
      this.$emit('doPost', this.ordem_servico);
    },
  },
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <form id="form-centro-resultado" role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-body>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_id" class="col-form-label">Código</label>
                  <div class="col">
                    <input disabled  class="form-control text-right" type="text"
                      placeholder="Gerado pelo sistema" id="centro_resultado_id">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_id" class="col-form-label">Cliente</label>
                  <div class="col">
                    <multiselect :options="item_ordem" >
                        
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_id" class="col-form-label">Situação</label>
                  <div class="col">
                    <multiselect :options="item_ordem" >
                        
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_id" class="col-form-label">Data e Hora de entrada</label>
                  <div class="col">
                    <input id="hora_data_id" type="datetime-local" class="form-control"
                              placeholder="dd/mm/aaaa hh:mm" />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-8">
                <div class="form-group">
                  <label for="centro_resultado_id" class="col-form-label">Descrição</label>
                  <div class="col">
                    <input disabled  class="form-control text-left" type="text"
                      placeholder="Gerado pelo sistema" id="descrição_ordem_id">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label for="centro_resultado_id" class="col-form-label">Tipo Movimento</label>
                  <div class="col">
                    <multiselect :options="item_ordem" >
                        
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Defeito</label>
                  <div class="col">
                    <input v-model="ordem_servico.nome" class="form-control" type="text"
                      placeholder="Identificação do Histórico Padrão" id="defeito_id">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-10">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Observação</label>
                  <div class="col">
                    <textarea class="form-control"  id="observacao_id" cols="10" rows="5">
                      
                    </textarea>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">N° Série</label>
                  <div class="col">
                    <input v-model="ordem_servico.ult_nf" class="form-control" type="text" placeholder="NF"
                      id="num_serie_id">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Prazo de Entrega</label>
                  <div class="col">
                    <input v-model="ordem_servico.ult_nf" class="form-control" type="text" placeholder="NF"
                      id="num_serie_id">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Dias Garantia</label>
                  <div class="col">
                    <input v-model="ordem_servico.ult_nf" class="form-control" type="text" placeholder="NF"
                      id="garantia_id">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-3">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Ordem de Compra</label>
                  <div class="col">
                    <input v-model="ordem_servico.ult_nf" class="form-control" type="text" placeholder="NF"
                      id="ordem_compra_id">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row form-group" role="group">
              
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Codigo</label>
                  <div class="col">
                    <input v-model="ordem_servico.incluso" type="text" class="form-control" placeholder="digite" typeof="text">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Nome Tecnico</label>
                  <div class="col">
                    <input v-model="ordem_servico.alterado" type="text" class="form-control" placeholder="digite" typeof="text">
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="form-group">
                  <label for="centro_resultado_descricao" class="col-form-label">Fatura</label>
                  <div class="col">
                    <input id="id_fatura" v-model="ordem_servico.alterado" type="text" class="form-control" placeholder="digite" typeof="text">
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </form>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-4">
        <button class="btn btn-light" >Reabrir </button>
        <button class="btn btn-light" >Faturar</button>
        <button class="btn btn-light" >Imprimir</button>
      </div>
    </div>
  </div>
</template>