<script>
import Detail from "./detail";
export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    list_ordem_servico: { type: Array, required: true },
    //list_ordem_servico
    hide: { type: Boolean, required: true }
  },
  components: { Detail },
  data() {
    return {
      titleBody: 'Ordem de Serviço',
      currentHistoricoPadrao: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { label: "ID", key: "id", sortable: true, tdClass: 'text-right', thClass: 'text-center' },
        { label: 'codigo', key: 'nome', sortable: true, thClass: 'text-center' },
        { label: "Cliente", key: "modelo", sortable: true, thClass: 'text-center' },
        { label: "Defeito", key: "check_btn1", sortable: true, thClass: 'text-center' },
        { label: "Num serie", key: "check_btn2", sortable: true, thClass: 'text-center' },
        { label: "Situação", key: "check_btn3", sortable: true, thClass: 'text-center' },
        { label: "Ações", key: "acoes", sortable: false, tdClass: 'text-center', thClass: 'text-center' }
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return (this.list_ordem_servico.length) ? this.list_ordem_servico.length : 0;
    },
    clonefoot() {
      return (this.list_ordem_servico.length || this.list_ordem_servico.length > 10) ? true : false;
    },
  },
  mounted() {
    this.$emit('newTitle', this.titleBody);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    editar(historico) {
      this.$emit('edit', historico);
    },
    excluir(historico) {
      this.$emit('doDelete', historico);
    },
    setHistoricoPadrao(historico) {
        this.currentHistoricoPadrao = historico;
    },
  },
}
</script>

<template>
<div class="card-body" v-if="hide">
  <div class="row mt-4">
    <!-- Start Limit -->
    <div class="col-sm-12 col-md-6">
      <div id="historico-padrao-table_length" class="dataTables_length">
        <label class="d-inline-flex align-items-center">
          Exibir&nbsp;
          <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
        </label>
      </div>
    </div>
    <!-- End Limit -->
    <!-- Start Search -->
    <div class="col-sm-12 col-md-6">
      <div id="historico-padrao-table_filter" class="dataTables_filter text-md-right">
        <label class="d-inline-flex align-items-center">
          Pesquisar:
          <b-form-input
          v-model="filter"
          type="search"
          placeholder="Pesquisar..."
          class="form-control form-control-sm ml-2"
          ></b-form-input>
        </label>
      </div>
    </div>
    <!-- End search -->
  </div>
   <!-- Start Table -->
   <div class="row mt-4">
    <div v-if="hide" class="table-responsive mb-0">
      <b-table
       :items="list_ordem_servico"
       :fields="fields"
       responsive="sm"
       :per-page="perPage"
       :current-page="currentPage"
       :sort-by.sync="sortBy"
       :sort-desc.sync="sortDesc"
       :filter="filter"
       :filter-included-fields="filterOn"
       @filtered="onFiltered"
       :hover=true
       :foot-clone=clonefoot
      >
        <template #cell(user_name)="row">
          {{ (row.item.user) ? row.item.user.name : 'Usuário não especificado' }}
        </template>
        <template #cell(status)="row">
          {{ (row.item.status ===1) ? 'Sim' : 'Não' }}
        </template>
        <template #cell(acoes)="row">
          <!-- `data.value` is the value after formatted by the Formatter -->
          <b-dropdown v-bind:id="'dropdown-'+ row.item.id" class="m-md-2">
            <template #button-content>
              <i data-v-6289eca4="" class="bx ri-menu-line"></i>
            </template>
            <b-dropdown-item v-b-modal.modal-historico-padrao @click="setHistoricoPadrao(row.item)"><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item>
            <b-dropdown-item @click="editar(row.item)"><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item>
            <b-dropdown-item @click="excluir(row.item)"><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
   </div>
   <!-- End Table -->
   <!-- Start Pagination -->
  <div v-if="hide" class="row mt-4">
    <div class="col">
      <div class="dataTables_paginate paging_simple_numbers float-right">
        <ul class="pagination pagination-rounded mb-0">
          <!-- pagination -->
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>
  </div>
  <!-- End Pagination -->
  <!-- Start Modal Centro Custo -->
  <b-modal
   v-if="hide"
   hide-footer
   id="modal-historico-padrao"
   :title="'Histórico Padrão'"
   title-class="font-18"
  >
    <Detail :historicoPadrao="currentHistoricoPadrao" />
  </b-modal>
  <!-- End Modal Centro Custo -->
</div>
</template>